import React, { useState, useEffect } from "react";
import "./Home.css";
import Timeline from "../Timeline/Timeline";
import Carousel from "react-bootstrap/Carousel";
import img2 from "../../Assets/img1 (2).webp";
import img3 from "../../Assets/img1 (7).webp";
import img4 from "../../Assets/img1 (9).webp";
import Shimmer from "../Shimmer"; // Adjust this import based on your actual file path

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the timeout duration as needed

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Shimmer />;
  }

  return (
    <div>
      <div className="carousel">
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 custom-height"
              src={img2}
              alt="Second slide"
            />
            <Carousel.Caption />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 custom-height"
              src={img3}
              alt="Third slide"
            />
            <Carousel.Caption />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 custom-height"
              src={img4}
              alt="Fourth slide"
            />
            <Carousel.Caption />
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="mission-content">
        <h2 className="text-center">Our Mission</h2>
        <p>
          Welcome to Vipplav AI, where our mission transcends the ordinary and
          ventures into the extraordinary. In a world where technology is
          evolving at lightning speed, we stand at the forefront, driven by a
          singular, powerful vision: to democratize artificial intelligence
          for the Indian masses, starting with the rich and vibrant Telugu
          language.
        </p>
        <p>
          Imagine a world where every individual, regardless of the language
          they speak, can harness the transformative power of AI. A world
          where technology bridges gaps, breaks down barriers, and uplifts
          communities. This is the world we envision at Vipplav AI. Our
          mission is to turn this visionary dream into a tangible reality,
          ensuring that AI is not just a tool for the privileged few but a
          beacon of empowerment for all.
        </p>
        <div className="highlight-box">
          <p>
            The future of AI is not a distant dream; it is being shaped today.
            At Vipplav AI, we are not just passive observers of this
            transformation; we are active architects. Our mission drives us to
            continuously push the boundaries of what is possible, to explore
            uncharted territories, and to bring forth solutions that are both
            revolutionary and accessible.
          </p>
        </div>
      </div>
      <Timeline />
    </div>
  );
};

export default Home;
