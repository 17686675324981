// Shimmer.js
import React from 'react';
import './Shimmer.css';

const Shimmer = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer-content">
        <div className="shimmer-item carousel-shimmer"></div>
        <div className="shimmer-item mission-shimmer"></div>
        <div className="shimmer-item timeline-shimmer"></div>
      </div>
    </div>
  );
};

export default Shimmer;
