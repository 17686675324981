import React from "react";
import oopsImg from "../Assets/Topic Title.png";  // Adjust this import based on your actual image path
import "./DynamicImage.css";  // Import the CSS file for styling

const DynamicImage = () => {
  return (
    <div className="dynamic-image-container text-center mt-5">
      <img src={oopsImg} alt="Dynamic Content" className="dynamic-image" />
    </div>
  );
};

export default DynamicImage;
