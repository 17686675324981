import React from 'react';
import { Link } from 'react-router-dom';
import { BsLinkedin, BsEnvelope } from 'react-icons/bs'; 
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="grid">
          <div>
            <h3>Quick Links</h3>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/tools">Tools</Link></li>
              <li><Link to="/blogs">Blogs</Link></li>
              <li><Link to="/aboutus">About Us</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
          <div>
            <h3>Help Center</h3>
            <ul>
              <li>
                <a href="mailto:hello@vipplavai.com">
                  <BsEnvelope /> Email
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/vipplav-ai/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BeQlzdRn9RiuvDnw0kizUGw%3D%3D">
                  <BsLinkedin /> LinkedIn
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-top flex">
          <p>&copy; 2024 Vipplav AI.</p>
          <div className="social-icons">
            <Link to="#"><BsLinkedin size={24} /></Link>
            <Link to="#"><BsEnvelope size={24} /></Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
